body {
  margin-top: 90px;
}

.navbar-brand-img {
  display: inline-block;
  width: $navbar-brand-height;
  height: $navbar-brand-height;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -4px;
}

.about-branding {
  max-width: 300px;
  filter: brightness(0) saturate(100%) invert(58%) sepia(83%) saturate(5861%) hue-rotate(196deg) brightness(101%) contrast(103%);
}

.ad {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  margin-top: 25px;

  text-align: center;

  @include clearfix;
}

footer {
  margin: 20px auto 40px;
  padding-top: 20px;


  .description {
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .links {

    text-align: center;

    ul {
      list-style-type: none;
      padding-left: 0;
      margin-left: 0;

      li {
        margin-left: 20px;
        margin-right: 20px;
        display: inline-block;

        padding-left: 0;

        @include media-breakpoint-down(xs) {
          display: block;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-left: auto;
          margin-right: auto;
        }

        a {
          color: $primary;
          font-size: $font-size-sm;
        }
      }
    }

    padding-bottom: 20px;
  }

  .branding, .description {
    text-align: center;
  }

  .branding {
    .penguin {
      display: inline-block;
      width: 50px;
      padding-right: 20px;
    }

    span {
      display: inline-block;
    }
  }
}

.social-share {
  text-align: center;
}

.device-information-hero {
  max-height: 200px;
}

.card {
  background-color: inherit;
  color: inherit;
}

.visible-logged-in {
  display: none;
}

div.alerts {
  position: fixed;
  bottom: 10px;

  @include make-container();

  .alert {
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(10);
      @include make-col-offset(1);
    }

    @include media-breakpoint-up(md) {
      @include make-col(6);
      @include make-col-offset(3);
    }
  }
}
