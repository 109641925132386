.captcha-wrapper {
  text-align: center;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  margin-top: 20px;
}

.external-login {
  &--google {
    background: #D50F25;
    color: white;
  }
}

.agoticon {
  padding-bottom: 0;
  padding-top: 0;
  width: 180px;
  height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;


  border: 1px solid #666;
  border-radius: $border-radius-lg;
}
