$dark-bg: #212121;

@media (prefers-color-scheme: dark) {
  body {
    background-color: $dark-bg;
    color: $white;
  }

  .nav-link.active {
    background-color: $dark-bg !important;
    color: $white !important;
  }

  table {
    color: inherit !important;
  }

  .table td, .table th, .nav-tabs, .nav-link:focus, .nav-link:hover, .card, .card * {
    border-color: $gray-800 !important;
  }

  .nav-link.active {
    border-color: $gray-800 !important;
    border-bottom-color: transparent !important;
  }

  input[type=text], input[type=text]:focus, .form-control:disabled {
    background: $gray-800;
    color: $white;
  }

  pre {
    color: $white;
  }
}
