.selector {
  @include make-row();

  margin-top: 40px;
  margin-bottom: 40px;

  margin-left: auto;
  margin-right: auto;

  &__navigation {
    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(3);
    }

    .steps {
      @include media-breakpoint-down(sm) {
        @include make-row();
      }

      .step {
        @include media-breakpoint-down(sm) {
          @include make-col-ready();
          @include make-col(3);
        }

        a {
          display: flex;
          align-items: center;
          width: 100%;

          padding: 15px;
          margin-bottom: 20px;

          color: $gray-500;


          &.active {
            color: $white;
            background: $primary;
            border-radius: $border-radius-lg;
          }

          &:hover {
            text-decoration: none;
            cursor: pointer;
          }

          @include media-breakpoint-down(sm) {
            justify-content: center;

            .description {
              display: none;
            }
          }

          @include media-breakpoint-up(md) {
            height: 100px;

            .num {
              &:after {
                content: ".";
              }

              padding-right: 5px;
            }
          }
        }
      }
    }

    .current-step {
      &-2 {
        .step:nth-child(1) a {
          color: $primary;
        }
      }

      &-3 {
        .step:nth-child(1) a, .step:nth-child(2) a {
          color: $primary;
        }
      }

      &-4 {
        .step:nth-child(1) a, .step:nth-child(2) a, .step:nth-child(3) a {
          color: $primary;
        }
      }
    }
  }

  &__wizard {
    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(9);
    }

    .product {
      text-align: center;

      color: inherit;

      padding-top: 40px;
      padding-bottom: 40px;

      border: 1px solid transparent;
      border-radius: $border-radius-lg;

      &:hover {
        border: 1px solid $primary;
        cursor: pointer;
        text-decoration: none;
      }

      $image-wrapper-height: 130px;

      &__image-wrapper {
        height: $image-wrapper-height;
        margin-bottom: 15px;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          align-self: flex-end;
          display: block;
          max-height: $image-wrapper-height;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          filter: drop-shadow(0 0 1px #000);
        }
      }
    }

    &--step-1 {
      .products {
        @include make-row(0);

        .product {
          @include make-col(6);

          @include media-breakpoint-up(lg) {
            @include make-col(4);
          }
        }
      }
    }

    &--step-2 {
      .products {
        @include make-row(0);

        .product {
          @include make-col-ready();
          @include make-col(3);

          @include media-breakpoint-down(md) {
            @include make-col(4);
          }
          @include media-breakpoint-down(sm) {
            @include make-col(6);
          }
        }
      }
    }

    h3 {
      text-align: center;
    }

    table {
      tr.firmware {

        td:first-child {
          font-size: 1.5em;
          padding: 0 10px;
          text-align: center;
          line-height: 1.4em;
          vertical-align: middle;
        }

        &:hover {
          background: $gray-200;
          @media (prefers-color-scheme: dark) {
            background: $gray-800;
          }
          cursor: pointer;
        }

        td.filename {
          text-align: right;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }

    .device-info {
      img {
        float: right;
        max-height: 100px;
        margin-right: 40px;
        margin-top: -50px;
        max-width: 400px;

        filter: drop-shadow(0 0 1px #000);
      }

      @include clearfix();
    }
  }
}

.btn-copy-to-clipboard {
  min-width: 200px;
}

.nav-tabs .nav-link {
  background: transparent;

  @media (prefers-color-scheme: dark) {
    color: white;
  }
}
